<template>
  <main class="KitchenSinkView">
    <div class="container">
      <header>
        <h1>Kitchen sink</h1>
      </header>

      <p class="lead">All Bootstrap and app components.</p>

      <!-- TYPO -->

      <section
        class="
        mt-5"
      >
        <div class="row">
          <div class="col-lg-6">
            <section class="mt-5">
              <h1>Heading 1</h1>
              <p class="lead">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi vitae maxime doloremque quidem, architecto, voluptatem quam eveniet, veritatis doloribus incidunt rerum nostrum aliquam eligendi libero perferendis? Maxime perferendis eum ipsam.</p>
              <h2>Heading 2</h2>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi vitae maxime doloremque quidem, architecto, voluptatem quam eveniet, veritatis doloribus incidunt rerum nostrum aliquam eligendi libero perferendis? Maxime perferendis eum ipsam.</p>
              <h3>Heading 3</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi vitae maxime doloremque quidem, architecto, voluptatem quam eveniet, veritatis doloribus incidunt rerum nostrum aliquam eligendi libero perferendis? Maxime perferendis eum ipsam.</p>
              <h4>Heading 4</h4>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi vitae maxime doloremque quidem, architecto, voluptatem quam eveniet, veritatis doloribus incidunt rerum nostrum aliquam eligendi libero perferendis? Maxime perferendis eum ipsam.</p>
              <h5>Heading 5</h5>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi vitae maxime doloremque quidem, architecto, voluptatem quam eveniet, veritatis doloribus incidunt rerum nostrum aliquam eligendi libero perferendis? Maxime perferendis eum ipsam.</p>
              <h6>Heading 6</h6>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi vitae maxime doloremque quidem, architecto, voluptatem quam eveniet, veritatis doloribus incidunt rerum nostrum aliquam eligendi libero perferendis? Maxime perferendis eum ipsam.</p>
            </section>

            <!-- BUTTONS -->

            <section class="mt-5">
              <section class="mt-5">
                <h2>Buttons</h2>

                <div class="mb-2 btn-rack">
                  <button class="mr-3 btn btn-primary">
                    Primary
                  </button>

                  <button class="mr-3 btn btn-secondary">
                    Secondary
                  </button>

                  <button class="mr-3 btn btn-success">
                    Success
                  </button>

                  <button class="mr-3 btn btn-danger">
                    Danger
                  </button>

                  <button class="mr-3 btn btn-warning">
                    warning
                  </button>

                  <button class="mr-3 btn btn-dark">
                    Dark
                  </button>

                  <button class="mr-3 btn btn-light">
                    Light
                  </button>
                </div>

                <div class="mb-2 btn-rack">
                  <button class="mr-3 btn btn-outline-primary">
                    Primary
                  </button>

                  <button class="mr-3 btn btn-outline-secondary">
                    Secondary
                  </button>

                  <button class="mr-3 btn btn-outline-success">
                    Success
                  </button>

                  <button class="mr-3 btn btn-outline-danger">
                    Danger
                  </button>

                  <button class="mr-3 btn btn-outline-warning">
                    warning
                  </button>

                  <button class="mr-3 btn btn-outline-dark">
                    Dark
                  </button>

                  <button class="mr-3 btn btn-outline-light">
                    Light
                  </button>
                </div>
              </section>
            </section>
          </div>

          <div class="col-lg-6">
            <!-- CARDS -->

            <section class="mt-5">
              <h2>Cards</h2>

              <div class="card mb-3">
                <div class="card-header">
                  Card
                </div>
                <div class="card-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora fugiat laborum impedit voluptatum incidunt corporis velit aperiam ipsam deleniti, ullam repellat esse quis, in porro accusantium dolore vel beatae cum.
                </div>
                <div class="card-footer">
                  Footer
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-header">
                  Card
                </div>
                <div class="card-body">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora fugiat laborum impedit voluptatum incidunt corporis velit aperiam ipsam deleniti, ullam repellat esse quis, in porro accusantium dolore vel beatae cum.
                </div>
                <div class="card-footer">
                  Footer
                </div>
              </div>
            </section>

            <!-- ALERTS -->

            <section class="mt-5">
              <h2>Alerts</h2>

              <div class="alert alert-primary">
                Primary
              </div>

              <div class="alert alert-secondary">
                Secondary
              </div>

              <div class="alert alert-success">
                Success
              </div>

              <div class="alert alert-danger">
                Danger
              </div>

              <div class="alert alert-warning">
                Warning
              </div>

              <div class="alert alert-info">
                Info
              </div>

              <div class="alert alert-dark">
                Dark
              </div>

              <div class="alert alert-light">
                Light
              </div>
            </section>
          </div>
        </div>
      </section>

      <!-- BUTTONS -->

      <!-- FORM -->

      <section class="mt-5">
        <h2>Form</h2>

        <div class="card">
          <div class="card-body">
            <form novalidate class="p-3">
              <div class="row g-5">
                <div class="col-lg-6 form">
                  <div class="mb-3">
                    <FormInput name="input1" placeholder="Placeholder" label="Input" :validator="v$.form.input" hint="Hint text..." :floating="true" />
                  </div>
                  <div class="mb-3">
                    <FormInput name="input2" placeholder="Placeholder" label="Input" :validator="v$.form.input" hint="Hint text..." />
                  </div>
                  <div class="mb-3">
                    <FormPassword name="input3" placeholder="Placeholder" label="Password" :validator="v$.form.password" hint="Hint text..." />
                  </div>
                  <div class="mb-3">
                    <FormTextarea name="input4" placeholder="Placeholder" label="Textarea" :validator="v$.form.textarea" hint="Hint text..." />
                  </div>
                  <div class="mb-3">
                    <FormSelect name="input5" placeholder="Placeholder" label="Select" :validator="v$.form.select" :options="options" hint="Hint text..." />
                  </div>
                  <div class="mb-3">
                    <FormFile name="input6" label="File" :validator="v$.form.file" hint="Hint text..." />
                  </div>
                  <div class="mb-3">
                    <FormRange
                      name="input7"
                      label="Range"
                      :validator="v$.form.range"
                      :min="0"
                      :max="100"
                      :step="5"
                      hint="Hint text..."
                    />
                  </div>
                </div>

                <div class="col-lg-6 form">
                  <div class="mb-3">
                    <AppUploader
                      ratio="16:9"
                      :label="'Uploader'"
                      :preview="filePreview"
                      :upload="doUpload"
                      @select="onFileSelected"
                      @remove="onFileRemoved"
                      @uploaded="onFileUploaded"
                      @error="onFileNotUploaded"
                    />
                  </div>

                  <div class="row">
                    <div class="col-4 mb-3">
                      <FormSwitch name="input8" label="Switch" :validator="v$.form.switch" text="Active" hint="Hint text..." />
                    </div>
                    <div class="col-4 mb-3">
                      <FormCheckboxes label="Checkbox" :validator="v$.form.checkboxes" :options="options" hint="Hint text..." />
                    </div>
                    <div class="col-4 mb-3">
                      <FormRadios label="Radios" :validator="v$.form.radios" :options="options" hint="Hint text..." />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <!-- LOADING -->

      <section class="mt-5">
        <h2>Loading</h2>
        <div class="mt-2 border">
          <AppLoading :space="false" :size="'20px'" />
        </div>
        <div class="mt-2 border">
          <AppLoading />
        </div>
        <div class="mt-2 border text-center">
          Loading <AppLoading :spaced="false" :size="'16px'" :inline="true" />
        </div>
      </section>

      <!-- MODAL -->

      <section class="mt-5">
        <h2>Modal</h2>

        <div class="border p-5">
          <div class="modal fade show" tabindex="-1" style="position:static; display:block">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Modal title</h5>
                  <button type="button" class="btn-close" />
                </div>
                <div class="modal-body">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique, numquam nesciunt! Veritatis molestiae voluptas unde, quo numquam illo magni veniam doloremque, dignissimos voluptatibus impedit voluptatum atque nisi doloribus, ducimus expedita.</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-outline-primary">Close</button>
                  <button type="button" class="btn btn-primary">Confirm</button>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center">
            <button class="btn btn-primary" @click="openModal()">
              Open Modal
            </button>
          </div>
        </div>
      </section>

      <!-- MODAL -->

      <section class="mt-5">
        <h2>Toast</h2>

        <div class="border p-5 text-center">
          <button class="mx-1 btn btn-primary" @click="openToast()">
            Open Toast
          </button>

          <button class="mx-1 btn btn-success" @click="openToast('success')">
            Open Toast
          </button>

          <button class="mx-1 btn btn-danger" @click="openToast('danger')">
            Open Toast
          </button>

          <button class="mx-1 btn btn-warning" @click="openToast('warning')">
            Open Toast
          </button>

          <button class="mx-1 btn btn-info" @click="openToast('info')">
            Open Toast
          </button>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import AppLoading from '@/components/AppLoading'
  import AppUploader from '@/components/AppUploader'
  import FormCheckboxes from '@/components/base/FormCheckboxes'
  import FormFile from '@/components/base/FormFile'
  import FormInput from '@/components/base/FormInput'
  import FormPassword from '@/components/base/FormPassword'
  import FormRadios from '@/components/base/FormRadios'
  import FormRange from '@/components/base/FormRange'
  import FormSelect from '@/components/base/FormSelect'
  import FormSwitch from '@/components/base/FormSwitch'
  import FormTextarea from '@/components/base/FormTextarea'
  import ModalAlert from '@/components/ModalAlert'

  import useModal from '@/plugins/modal/useModal'
  import useToast from '@/plugins/toast/useToast'
  import useVuelidate from '@vuelidate/core'
  import { required, minValue, maxValue } from '@vuelidate/validators'

  export default {
    components: {
      AppUploader,
      AppLoading,
      FormCheckboxes,
      FormFile,
      FormInput,
      FormPassword,
      FormRadios,
      FormRange,
      FormSelect,
      FormSwitch,
      FormTextarea
    },

    setup() {
      return {
        v$: useVuelidate(),
        modal$: useModal(),
        toast$: useToast()
      }
    },

    data() {
      return {
        filePreview: null,
        fileSelected: '',
        fileUploaded: '',
        fileNotUploaded: '',
        form: {
          checkboxes: [
            'one'
          ],
          radios: 'two'
        },
        options: { one: 'One', two: 'Two' }
      }
    },

    validations() {
      return {
        form: {
          checkboxes: { required },
          file: { required },
          input: { required },
          password: { required },
          radios: { required },
          range: { required, minValue: minValue(5), maxValue: maxValue(95) },
          select: { required },
          switch: { required },
          textarea: { required }
        }
      }
    },

    methods: {
      openModal() {
        this.modal$.open(ModalAlert).onClose((confirm) => {
          if (confirm) {
          }
        })
      },

      openToast(type) {
        this.toast$[type || 'message'](type, 'A toast is opened')
      },

      doUpload() {
        return new Promise((resolve) => {
          setTimeout(() => {
            this.filePreview = 'https://www.lepoint.fr/images/2018/05/01/14664522lpw-14664521-article-jpg_5206530.jpg'
            resolve({
              id: new Date().getTime(),
              name: 'fakefile.jpg'
            })
          }, 1000)
        })
      },

      onFileSelected(response) {
        this.fileSelected = response

        if (response.errors) {
          //console.log(response.errors)
        }
      },

      onFileRemoved() {
        this.fileSelected = null
        this.fileUploaded = null
      },

      onFileUploaded(response) {
        this.fileUploaded = response
      },

      onFileNotUploaded(response) {
        this.fileNotUploaded = response
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .btn-rack
    display: flex
    gap: $spacer-1
    flex-wrap: wrap
</style>

