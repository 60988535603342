<template>
  <FormGroup :id="id" :label="label" :validator="validator" :hint="hint">
    <div>
      <div class="form-check" v-for="(optionText, optionKey) in options" :key="optionKey" :class="{ 'form-check-inline': inline }">
        <input
          class="form-check-input"
          autocomplete
          v-bind="$attrs"
          v-model="model"
          :type="type"
          :class="{ 'is-invalid': validator && validator.$error }"
          :id="normalizeKey(optionKey)"
          :value="getValue(optionKey, optionText)"
          @change="onUpdateValue"
        >
        <label class="form-check-label" :for="normalizeKey(optionKey)">
          {{ getText(optionKey, optionText) }}
        </label>
      </div>
    </div>
  </FormGroup>
</template>

<script>
  import generateId from '@/shared/utils/generateId'
  import FormGroup from '@/components/base/FormGroup'

  export default {
    components: {
      FormGroup
    },

    props: {
      label: {
        type: String,
        default: null
      },
      hint: {
        type: String,
        default: null
      },
      options: {
        type: [ Array, Object ],
        default: null
      },
      fieldKey: {
        type: String,
        default: 'id'
      },
      fieldText: {
        type: String,
        default: 'name'
      },
      inline: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: 'checkbox'
      },
      validator: {
        type: Object,
        default: null
      },
      modelValue: {
        type: String,
        default: ''
      }
    },

    emits: [ 'update:modelValue' ],

    data() {
      return {
        id: generateId(),
        model: this.modelValue
      }
    },

    watch: {
      validator: {
        immediate: true,
        deep: true,
        handler(validator) {
          if (validator) {
            this.model = validator.$model
          }
        }
      },

      modelValue: {
        immediate: true,
        handler(modelValue) {
          if (!this.validator) {
            this.model = modelValue
          }
        }
      }
    },

    methods: {
      normalizeKey(key) {
        return this.id + '-' + ('' + key).replace(/ /g, '-').toLowerCase()
      },

      getValue(key, value) {
        if (Array.isArray(this.options)) {
          return value[this.fieldKey]
        } else {
          return key
        }
      },

      getText(key, value) {
        if (Array.isArray(this.options)) {
          return value[this.fieldText]
        } else {
          return value
        }
      },

      onUpdateValue() {
        if (this.validator) {
          // eslint-disable-next-line vue/no-mutating-props
          this.validator.$model = this.model
        } else {
          this.$emit('update:modelValue', this.model)
        }
      }
    }
  }
</script>
