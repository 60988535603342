<template>
  <FormBoxes v-bind="$attrs" type="checkbox" />
</template>

<script>
  import FormBoxes from '@/components/base/FormBoxes'

  export default {
    components: {
      FormBoxes
    }
  }
</script>
