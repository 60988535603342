<template>
  <div class="ModalAlert">
    <div class="modal-header">
      <h5 class="modal-title">
        {{ title }}
      </h5>
      <button type="button" class="btn-close" @click="modal.close()" />
    </div>
    <div class="modal-body">
      {{ text }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" v-focus @click="modal.close()">Close</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'Alert'
      },
      text: {
        type: String,
        default: 'This is an alert...'
      },
      modal: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>
