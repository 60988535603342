<!--
  <AppUploader
    label="Upload file"
    icon="fas fa-file"
    @select="onFileSelected"
    @remove="onFileRemoved"
    @upload="onFileUploaded"
    @error="onFileNotUploaded"
  >
    <template slot-scope="{ uploader }">
      <p>{{ uploader.file.name }} is uploaded</p>
    </template>
  </AppUploader>
-->

<template>
  <div class="AppUploader" :style="ratioStyle">
    <div class="AppUploader__wrapper">
      <AppLoading v-if="uploading" />

      <div class="AppUploader__label" v-if="!selected && !uploading">
        <i :class="icon.split(' ')" />
        {{ label }}
      </div>

      <button class="AppUploader__remove" v-if="removable && selected && !uploading" @click="onRemove">
        &times;
      </button>

      <slot v-if="selected" :uploader="selected">
        <div class="AppUploader__preview" :style="backgroundStyle" />
      </slot>

      <input
        class="AppUploader__input"
        ref="input"
        type="file"
        v-bind="$attrs"
        :accept="types.join(',')"
        @change="onChange"
      >
    </div>
  </div>
</template>

<script>
  import AppLoading from '@/components/AppLoading'

  export default {

    components: {
      AppLoading
    },
    props: {
      label: {
        type: String,
        default: ''
      },
      preview: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      ratio: {
        type: String,
        default: '16:9'
      },
      minSize: {
        type: Number,
        default: null
      },
      maxSize: {
        type: Number,
        default: null
      },
      minWidth: {
        type: Number,
        default: null
      },
      maxWidth: {
        type: Number,
        default: null
      },
      minHeight: {
        type: Number,
        default: null
      },
      maxHeight: {
        type: Number,
        default: null
      },
      minWidthHeight: {
        type: Number,
        default: null
      },
      maxWidthHeight: {
        type: Number,
        default: null
      },
      upload: {
        type: Function,
        default: () => new Promise(function () {})
      },
      readMode: {
        type: String,
        default: 'readAsDataURL'
      },
      autoUpload: {
        type: Boolean,
        default: true
      },
      removable: {
        type: Boolean,
        default: true
      },
      types: {
        type: Array,
        default: () => [ 'image/*' ]
      },
      default: {
        type: Object,
        default: null
      }
    },

    emits: [ 'remove', 'select', 'upload', 'error', 'uploaded' ],

    data() {
      return {
        uploading: false,
        selected: this.default,
        background: this.preview
      }
    },

    computed: {
      backgroundStyle() {
        if (this.background) {
          return { 'background-image': 'url("' + this.background + '")' }
        }

        return {}
      },

      ratioStyle() {
        if (this.ratio) {
          const ratio = this.ratio
          const splits = ratio.split(':')

          return {
            'padding-top': 100 / splits[0] * splits[1] + '%'
          }
        }

        return {}
      }
    },

    watch: {
      preview(value) {
        this.background = value
        this.selected = {}
      },
      selected(value) {
        this.selected = value
      }
    },

    methods: {
      onChange(event) {
        let reader

        let file
        const fileList = event.target

        if (fileList.files && fileList.files[0]) {
          file = fileList.files[0]

          reader = new FileReader()
          reader[this.readMode](fileList.files[0])
          reader.onload = (eventRead) => {
            file.content = eventRead.target.result

            try {
              const image = new Image()

              if (file.type.indexOf('image/') === 0) {
                image.src = eventRead.target.result
                image.onload = () => {
                  file.width = image.width
                  file.height = image.height
                  this.validate(file)
                }
              } else {
                this.validate(file)
              }
            } catch (e) {
              this.validate(file)
            }
          }
        }
      },

      onRemove() {
        this.$emit('remove', Object.assign({}, this.selected))
        this.selected = null
        this.background = null
      },

      validate(file) {
        let isValid = true
        const data = {}
        const errors = {}

        if (this.types.length && this.types.indexOf(file.type.replace(/\/.*$/, '/*')) === -1 && this.types.indexOf(file.type) === -1) {
          errors.type = {
            actualValue: file.type,
            requiredValue: this.types.join(', ')
          }
        }

        if (this.minSize !== null && file.size < this.minSize) {
          errors.minSize = {
            actualValue: file.size,
            requiredValue: this.minSize
          }
        }

        if (this.maxSize !== null && file.size > this.maxSize) {
          errors.maxSize = {
            actualValue: file.size,
            requiredValue: this.maxSize
          }
        }

        if (file.width) {
          if (this.minWidth !== null && file.width < this.minWidth) {
            errors.minWidth = {
              actualValue: file.width,
              requiredValue: this.minWidth
            }
          }

          if (this.maxWidth !== null && file.width > this.maxWidth) {
            errors.maxWidth = {
              actualValue: file.width,
              requiredValue: this.maxWidth
            }
          }

          if (this.minHeight !== null && file.height < this.minHeight) {
            errors.minHeight = {
              actualValue: file.height,
              requiredValue: this.minHeight
            }
          }

          if (this.maxHeight !== null && file.height > this.maxHeight) {
            errors.maxHeight = {
              actualValue: file.height,
              requiredValue: this.maxHeight
            }
          }

          if (this.minWidthHeight !== null && Math.min(file.width, file.height) < this.minWidthHeight) {
            errors.minWidthHeight = {
              actualValue: file.height + 'x' + file.height,
              requiredValue: this.minWidthHeight
            }
          }

          if (this.maxWidthHeight !== null && Math.max(file.width, file.height) > this.maxWidthHeight) {
            errors.maxWidthHeight = {
              actualValue: file.height + 'x' + file.height,
              requiredValue: this.maxWidthHeight
            }
          }
        }

        this.$refs.input.value = ''

        isValid = !Object.keys(errors).length

        data.$valid = isValid
        data.$invalid = !isValid
        data.raw = file
        data.file = {
          name: file.name,
          size: file.size,
          type: file.type,
          date: file.lastModifiedDate,
          content: file.content
        }

        if (!isValid) {
          data.errors = errors
        }

        // eslint-disable-next-line prefer-reflect
        delete data.raw.content

        this.$emit('select', data)

        if (data.$valid) {
          const formData = new FormData()

          formData.append('file', file)

          this.selected = data

          if (this.autoUpload) {
            this.doUpload(data, formData)
          } else if (file.type.indexOf('image/') === 0) {
            this.background = data.file.content
          }
        }
      },

      doUpload(file) {
        this.uploading = true

        this.upload(file.raw, this.params)
          .then((response) => {
            this.$emit('uploaded', response)
          })
          .catch((error) => {
            this.$emit('error', error)
          })
          .finally(() => {
            this.uploading = false
          })
      }
    }
  }
</script>

<style lang="sass">
  @import '@/sass/styles.vars.all'

  .AppUploader
    border: 4px dashed $gray-400
    width: 100%
    position: relative

  .AppUploader__wrapper
    overflow: visible
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0

    .AppLoading
      position: absolute
      min-height: 100%
      min-width: 100%
      z-index: 2

  .AppUploader__label
    color: $gray-500
    text-align: center

    i
      display: block
      font-size: 3em

  .AppUploader__input
    opacity: 0
    position: absolute
    min-height: 100%
    min-width: 100%
    z-index: 2

    &,
    &::-webkit-file-upload-button
      cursor: pointer

  .AppUploader__preview
    position: absolute
    top: $spacer-1
    left: $spacer-1
    right: $spacer-1
    bottom: $spacer-1
    background: transparent center center no-repeat
    background-size: cover
    z-index: 1

  .AppUploader__remove
    border: 1px solid $gray-500
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    width: 1.6em
    height: 1.6em
    line-height: 0
    box-shadow: 0 2px 2px rgba(black, .2)
    position: absolute
    top: -18px
    right: -18px
    background: white
    z-index: 3
    font-size: 1.5rem
    font-weight: 700
    line-height: 1
    color: $gray-500
    text-shadow: 0 1px 0 #fff
</style>
