<template>
  <FormGroup :id="id" :label="label" :validator="validator" :hint="hint">
    <input
      type="file"
      class="form-control"
      autocomplete
      v-bind="$attrs"
      :id="elmtId"
      :name="name"
      :multiple="multiple"
      :accept="accept"
      :class="{ 'is-invalid': validator && validator.$error }"
      @change="onUpdateValue"
    >
  </FormGroup>
</template>

<script>
  import generateId from '@/shared/utils/generateId'
  import FormGroup from '@/components/base/FormGroup'

  export default {
    components: {
      FormGroup
    },

    props: {
      label: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: null
      },
      multiple: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: null
      },
      accept: {
        type: String,
        default: null
      },
      validator: {
        type: Object,
        default: null
      },
      modelValue: {
        type: Object,
        default: null
      }
    },

    emits: [ 'update:modelValue' ],

    data() {
      return {
        elmtId: this.id || generateId(),
        model: null
      }
    },

    methods: {
      onUpdateValue(event) {
        if (this.validator) {
          // eslint-disable-next-line vue/no-mutating-props
          this.validator.$model = event.target.files
        } else {
          this.$emit('update:modelValue', event.target.files)
        }
      }
    }
  }
</script>
